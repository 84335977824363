
import './style.css';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Stats from "three/examples/jsm/libs/stats.module"

const frames = require("./cube_animation.json");

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

const renderer = new THREE.WebGLRenderer();
const controls = new OrbitControls( camera, renderer.domElement );
controls.enableDamping = true;
controls.maxDistance = 10.0;
controls.minDistance = 1.0;

let stats : Stats;
let audio : HTMLAudioElement;

renderer.setSize(window.innerWidth, window.innerHeight);

var material = new THREE.MeshBasicMaterial ( { color : 0xFFFFFF, wireframe : false } );
var sphere = new THREE.SphereGeometry(0.05, 16, 16);

camera.position.set ( 0, 0, 5 );

let allSpheres = [];
let frame = 0;

let centroid = new THREE.Vector3(0, 0, 0);

window.addEventListener( 'resize', onWindowResize, false );

function onWindowResize()
{
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );
}

function getOrMakeSphere ( index : number ) : THREE.Mesh
{
	if ( index < allSpheres.length )
	{
		return allSpheres[index];
	}else
	{
		var mesh = new THREE.Mesh ( sphere, material );
		scene.add ( mesh );
		allSpheres.push ( mesh );
		return mesh;
	}
}


function animate ( ) : void 
{
	requestAnimationFrame(animate);
 	render();
 	//stats.update();
}

function render ( ): void 
{	
	stats.begin();

	let timeRatio = audio.currentTime / audio.duration;
	let currentFrame = Math.round(timeRatio * frames.length);

	let frameData = frames[currentFrame];

 	let avg = new THREE.Vector3();

 	for ( let i = 0; i < allSpheres.length; i++ ) allSpheres[i].visible = false;
 	for ( let i = 0; i < frameData.length; i++ )
 	{
 		let position = new THREE.Vector3 ( frameData[i][0], frameData[i][1], frameData[i][2] );
 		let sphere = getOrMakeSphere(i);
 		sphere.position.copy ( position );
 		sphere.visible = true;
 		avg.add ( position );
 	}

 	avg.divideScalar ( parseFloat(frameData.length) );
 	centroid.lerp ( avg, 0.1 );

 	controls.target = centroid;
 	camera.lookAt ( centroid );

 	frame += 30.0/60.0;
 	frame %= frames.length;

 	controls.update ( );
  	renderer.render ( scene, camera );
  	stats.end();
}

function start( ) 
{
	document.body.appendChild(renderer.domElement);

	stats = Stats();
	document.body.appendChild(stats.domElement);

	audio.play();
	animate ( );
}

audio = document.getElementById ( "audio" );
var b = document.getElementById("startBtn")
 	b.onclick = function() 
    { 
     	start(); 
     	document.body.removeChild(b.parentNode); 
     	
    }
